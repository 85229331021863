<script>
import useVuelidate from '@vuelidate/core'
import {required, requiredIf} from '@vuelidate/validators'

/* COMPOSANTS */
import AkFormView from "@components/layout/AkFormView";
import AkFormSubmitted from "@components/general/AkFormSubmitted";
import AkDropdown from "@components/input/AkDropdown";
import LinkRow from "@views/link/components/LinkRow";
import AkDateTime from "@components/input/AkDateTime";
import AkDialog from "@components/general/AkDialog";
import Toast from 'primevue/toast';

/* MIXINS */
import randomRef from "@mixins/randomRef";
import roleMixin from "@mixins/roleMixin";
import dateFormatter from "@mixins/dateFormatter";
import trafficConditionConst from "@mixins/const/trafficConditionConst";
import roadwayStateConst from "@mixins/const/roadwayStateConst";
import snowStateConst from "@mixins/const/snowStateConst";
import linkTypeConst from "@mixins/const/linkTypeConst";
import linkNightConst from "@mixins/const/linkNightConst";
import typeVhConst from "@mixins/const/typeVhConst";

/* SERVICES */
import linkSheetService from "@services/linkSheetService";
import agerService from "@services/agerService";
import uerService from "@services/uerService";
import ceiService from "@services/ceiService";
import circuitService from "@services/circuitService";
import rolePermissionService from "@services/rolePermissionService";
import AkCheckboxButton from "@components/input/AkCheckboxButton";
import AkInputText from "@components/input/AkInputText";
import affectationService from "@services/affectationService";

export default {
  components: {AkInputText, AkCheckboxButton, AkFormView, AkFormSubmitted, AkDropdown, LinkRow, AkDateTime, AkDialog, Toast},
  mixins: [randomRef, dateFormatter, trafficConditionConst, roadwayStateConst, snowStateConst, roleMixin, linkTypeConst, linkNightConst, typeVhConst],
  setup() {
    return {v$: useVuelidate()}
  },
  metaInfo() {
    return {
      title: "link_sheet.add",
    }
  },
  data() {
    return {
      submitted: false,
      validationSubmitted: false,
      agerList: [],
      uerList: [],
      ceiList: [],
      circuitList: [],
      disableAger: null,
      disableUer: null,
      disableCei: null,
      showTypeVh: false,
      current: {
        ceiId: null,
        uerId: null,
        agerId: null,
        typeVh: null,
        night: false,
        type: 'START',
        date: new Date(),
      },
      linkRows: [],
      copy: {
        data: null,
      },
      affectations: [],
    };
  },
  validations() {
    return {
      current: {
        date: {required},
        type: {required},
        agerId: {required},
        uerId: {required},
        ceiId: {required},
        typeVh: {required: requiredIf(this.showTypeVh)},
      },
    }
  },
  mounted() {
    this.getRef().showTotalLoader();

    let p4 = circuitService.findAllCurrent();
    p4.then(data => this.circuitList = data);

    let p5 = rolePermissionService.authorizeAccessByPermission(this.permissionConst.link.edit);

    let p6 = affectationService.findAllByUserId(this.currentUser.userId);
    p6.then(data => {
      this.affectations = data.affectations;
      this.agerList = data.agerList;
      if (this.agerList.length === 1) {
        this.current.agerId = this.agerList[0].id;
        this.disableAger = true;
      }

      this.uerList = data.uerList;
      if (this.uerList.length === 1) {
        this.current.uerId = this.uerList[0].id;
        this.disableUer = true;
      }

      this.ceiList = data.ceiList;
      if (this.ceiList.length === 1) {
        this.current.ceiId = this.ceiList[0].id;
        this.disableCei = true;
      }
    });

    Promise.all([p4, p5]).then(()=>{
      this.getRef().hideLoader();
    });
  },
  methods: {
    createSheet() {
      if (!this.checkForm()) {
        this.getRef().error(this.$t("link_sheet.error.create"));
        return;
      }

      this.getRef().showLoader(this.$refs.form)
      let linkSheetDTO = {
        linkSheet: this.current,
        linkRows: this.linkRows,
        validated: this.validationSubmitted
      }
      linkSheetService.create(linkSheetDTO).then(data => {
        let msg = this.$t(this.validationSubmitted ? "link_sheet.added_validated" : "link_sheet.added");
        this.$router.replace({ path: `/link/${data.linkSheet.id}/details`, query: { msg: msg } });
        this.$refs.dialogValidate.hide();
      }).catch(e=>{
            this.getRef().hideLoader();
            this.getRef().error(this.$t("error."+e.response.data.error));
            this.submitted = false;
            this.validationSubmitted = false;
            this.$refs.dialogValidate.hide();
          }
      );
    },
    createAndValidate() {
      this.validationSubmitted = true;
      this.createSheet();
    },
    initLinkRows() {
      this.linkRows = [];
      for (const element of this.circuitListForCurrent) {
        let circuit = element;
        this.linkRows.push({
          circuitId: circuit.id,
          agerId: circuit.agerId,
          uerId: circuit.uerId,
          ceiId: circuit.ceiId,
          circuitNumber: circuit.number,
          circuitSection: circuit.section,
          circuitAxis: circuit.axis,
          roadwayState: null,
          snowState: null,
          trafficCondition: null,
        })
      }
      this.linkRows.sort((a,b) => {
        return a.circuitNumber.localeCompare(b.circuitNumber);
      });
    },
    copyRow(index) {
      let row = this.linkRows[index];
      this.copy.data = {
        agentNumber: row.agentNumber,
        airTemp: row.airTemp,
        alertHour: row.alertHour,
        brineLiters: row.brineLiters,
        curative: row.curative,
        drySaltTonnage: row.drySaltTonnage,
        endHour: row.endHour,
        freezingFog: row.freezingFog,
        freezingHumidity: row.freezingHumidity,
        groundFrost: row.groundFrost,
        groundTemp: row.groundTemp,
        humidity: row.humidity,
        obs1: row.obs1,
        obs2: row.obs2,
        obs3: row.obs3,
        patrol: row.patrol,
        precurative: row.precurative,
        rainOnFrozenGround: row.rainOnFrozenGround,
        rainOnSurfusion: row.rainOnSurfusion,
        roadwayState: row.roadwayState,
        scraping: row.scraping,
        singularPoint: row.singularPoint,
        snowState: row.snowState,
        startHour: row.startHour,
        trafficCondition: row.trafficCondition,
      }
      this.$toast.add({severity:'success', summary: this.$t("form.row_copied"), life: 1000});
    },
    pasteRow(index) {
      if (this.copy.data) {
        let row = this.linkRows[index];
        row.agentNumber = this.copy.data.agentNumber;
        row.airTemp = this.copy.data.airTemp;
        row.alertHour = this.copy.data.alertHour;
        row.brineLiters = this.copy.data.brineLiters;
        row.curative = this.copy.data.curative;
        row.drySaltTonnage = this.copy.data.drySaltTonnage;
        row.endHour = this.copy.data.endHour;
        row.freezingFog = this.copy.data.freezingFog;
        row.freezingHumidity = this.copy.data.freezingHumidity;
        row.groundFrost = this.copy.data.groundFrost;
        row.groundTemp = this.copy.data.groundTemp;
        row.humidity = this.copy.data.humidity;
        row.obs1 = this.copy.data.obs1;
        row.obs2 = this.copy.data.obs2;
        row.obs3 = this.copy.data.obs3;
        row.patrol = this.copy.data.patrol;
        row.precurative = this.copy.data.precurative;
        row.rainOnFrozenGround = this.copy.data.rainOnFrozenGround;
        row.rainOnSurfusion = this.copy.data.rainOnSurfusion;
        row.roadwayState = this.copy.data.roadwayState;
        row.scraping = this.copy.data.scraping;
        row.singularPoint = this.copy.data.singularPoint;
        row.snowState = this.copy.data.snowState;
        row.startHour = this.copy.data.startHour;
        row.trafficCondition = this.copy.data.trafficCondition;
        this.$toast.add({severity:'success', summary: this.$t("form.row_pasted"), life: 1000});
      } else {
        this.$toast.add({severity:'error', summary: this.$t("form.no_row_copied"), life: 3000});
      }
    },
    openValidationDialog() {
      if (!this.checkForm()) return;
      this.$refs.dialogValidate.show();
    },
    checkForm() {
      this.getRef().resetMessages();
      this.submitted = true;
      this.v$.$touch();
      return !this.v$.$error;
    },
  },
  computed: {
    uerListForCurrent() {
      return this.uerList.filter(uer => uer.agerId === this.current.agerId);
    },
    ceiListForCurrent() {
      return this.ceiList.filter(cei => cei.uerId === this.current.uerId);
    },
    circuitListForCurrent() {
      let cei = this.ceiList.filter(c => c.id === this.current.ceiId)[0];
      if (cei && cei.displayTypeVh) {
        if (this.current.typeVh) {
          if (this.current.typeVh === "HIGHWAY") return this.circuitList.filter(c => c.typeVh === "HIGHWAY" && cei.uerId === c.uerId);
          if (this.current.typeVh === "NATIONAL") return this.circuitList.filter(c => c.typeVh === "NATIONAL" && cei.uerId === c.uerId);
        } else return [];
      }
      return this.circuitList.filter(c => c.ceiId === this.current.ceiId);
    },
    currentDateNext() {
      return this.formatDate(this.addDays(this.current.date, 1));
    },
    isMorning() {
      return this.findHours(new Date()) < 12;
    },
  },
  watch: {
    uerListForCurrent() {
      if(!this.uerListForCurrent.some(uer => uer.id===this.current.uerId))
        this.current.uerId = undefined;
    },
    ceiListForCurrent() {
      if(!this.ceiListForCurrent.some(cei => cei.id===this.current.ceiId))
        this.current.ceiId = undefined;
    },
    circuitListForCurrent() {
      let cei = this.ceiList.filter(c => c.id === this.current.ceiId)[0];
      if (cei && cei.displayTypeVh && !this.current.typeVh) return;
      this.initLinkRows();
    },
    'current.ceiId'(newVal) {
      this.linkRows = [];
      this.current.typeVh = null;
      if (!newVal) this.showTypeVh = false;
      else this.showTypeVh = this.ceiList.filter(c => c.id === newVal)[0].displayTypeVh;
    },
    'current.night'(newVal) {
      if (!newVal) return;
      if (this.isToday(this.current.date)) {
         if (this.isMorning)
           this.current.date = this.toDate(this.formatDate(this.addDays(new Date(), -1)));
         else this.current.date = new Date();
      }
    }
  }
}
</script>

<template v-if=!roleLoading>
  <Toast/>
  <AkFormView :ref="ref" :title="$t('link_sheet.add')">
    <template v-slot:form>
      <AkFormSubmitted :submitted=submitted reference="form">
        <div class="form-row">
          <AkCheckboxButton v-model="current.night"
             :label="$t('day_night')"
             :multiple="false"
             :options=linkNightConst
             class-name="col-md-2"/>
          <AkDateTime v-model="current.date"
             :label="$t('date')"
             :showTime=false
             :validator="v$.current.date"
             class-name="col-md-2"/>
          <AkInputText v-if="current.night"
             v-model="currentDateNext"
             :disabled=true
             :label="$t('date_after')"
             class-name="col-md-2"/>
        </div>
        <div class="form-row">
          <AkDropdown :label="$t('link_sheet.type')"
            v-model="current.type"
            :validator="v$.current.type"
            :submitted=this.submitted
            :options="linkTypeConst"
            class-name="col-md-4"/>
        </div>
        <div class="form-row">
          <AkDropdown :label="$t('ager_label')"
            v-model="current.agerId"
            :validator="v$.current.agerId"
            :submitted="this.submitted"
            :options="this.agerList"
            :disabled="disableAger"
            option-value="id"
            class-name="col-md-4"/>
          <AkDropdown :label="$t('uer_label')"
            v-if="current.agerId"
            v-model="current.uerId"
            :validator="v$.current.uerId"
            :submitted="this.submitted"
            :options="this.uerListForCurrent"
            :disabled="disableUer"
            option-value="id"
            class-name="col-md-4"/>
          <AkDropdown :label="$t('cei_label')"
            v-if="current.uerId"
            v-model="current.ceiId"
            :validator="v$.current.ceiId"
            :submitted="this.submitted"
            :options="this.ceiListForCurrent"
            :disabled="disableCei"
            option-value="id"
            class-name="col-md-4"/>
        </div>
        <div class="form-row" v-if="showTypeVh">
          <AkCheckboxButton v-model="current.typeVh"
            class-name="col-md-6"
            :label="$t('type_vh_label')"
            :multiple="false"
            :options=typeVhConst
            :validator="v$.current.typeVh"
            :submitted="this.submitted"/>
        </div>
        <div class="mt-2 row">
          <LinkRow 
            v-for="(row, index) in linkRows" 
            :key="index" 
            :row="row" 
            :index="index"
            ref="panels" 
            :submitted=this.submitted 
            @copyRow="copyRow" 
            @pasteRow="pasteRow"/>
        </div>
        <div class="float-right">
          <button 
            @click=openValidationDialog() 
            class="btn btn-success">
            {{ $t('validate_send') }}
          </button>
          <button 
            @click=createSheet() 
            class="btn btn-primary" style="margin-left: 10px">
            {{ $t('save_draft') }}
          </button>
        </div>
      </AkFormSubmitted>
    </template>
    
    <template v-slot:extra>
      <AkDialog ref="dialogValidate"
        :cancel-label="$t('no')"
        :title="$t('link_sheet.validate_dialog')"
        :validate-label="$t('yes')"
        width="450px"
        @validate="this.createAndValidate()">
        <div class="confirmation-content">
          <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem"/>
          <span >{{ $t('link_sheet.confirm_validation') }}</span>
        </div>
      </AkDialog>
    </template>
  </AkFormView>
</template>