export default {
  data() {
    return {
      linkNightConst: [
        {label: this.$t('link_night.day'), value: false},
        {label: this.$t('link_night.night'), value: true}
      ]
    }
  },
  methods: {
    displayForLinkNightConst(value) {
      let result = this.linkNightConst.find(el => el.value === value);
      return result ? result.label : '';
    }
  }
}